import commonHelper from '@/app/utility/common.helper.utility';
import TaxMasterDetails from '../taxMaster/taxMasterDetails';
export default {
  name: 'TaxMaster',
  components: {
    TaxMasterDetails
  },
  watch: {
    currentPage: function() {
      this.getTaxMasterList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTaxMasterList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showTaxMasterDetails: false,
      loader: false,
      editMode: false,
      updateMode: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      payload: {},
      totalRows: null,
      taxName: null,
      taxGroup: null,
      data: [],
      fields: [
        {
          key: 'tax_master_id',
          class: 'd-none'
        },
        {
          key: 'tax_name'
        },
        {
          key: 'tax_group'
        },
        {
          key: 'tax_type'
        },
        {
          key: 'section',
          label: 'Tax Section'
        },
        {
          key: 'tax_basis_meaning',
          label: 'Tax Basis'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'tax_group_id',
          class: 'd-none'
        },
        {
          key: 'tax_type_id',
          class: 'd-none'
        },
        {
          key: 'tax_section_id',
          class: 'd-none'
        },
        {
          key: 'tax_basis',
          class: 'd-none'
        },
        {
          key: 'recovery_percentage',
          class: 'd-none'
        },
        {
          key: 'rounding_factor',
          class: 'd-none'
        },
        {
          key: 'adhoc',
          class: 'd-none'
        },
        {
          key: 'recovery_flag',
          class: 'd-none'
        },
        {
          key: 'tax_rate',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showTaxMasterDetails = true;
        }
        if (this.showTaxMasterDetails) {
          setTimeout(() => {
            this.eventBus.$emit('createFormActions', actionName);
          }, 0);
        }
        if (actionName === 'download' && !this.showTaxMasterDetails) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'defineTaxMaster/getTaxMasterList',
            'tax-Master',
            () => (this.loader = false)
          );
        }
      }
    });
    this.getTaxMasterList();
  },
  methods: {
    getTaxMasterList() {
      this.payload = {
        tax_name: this.taxName,
        tax_group: this.taxGroup,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store
        .dispatch('defineTaxMaster/getTaxMasterList', this.payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      if (this.$router.currentRoute.name === 'searchTaxMaster') {
        this.showTaxMasterDetails = true;
        setTimeout(() => {
          this.eventBus.$emit('taxMasterDetails', rowData);
        }, 0);
      } else {
        this.$emit('selectedTaxName', rowData);
      }
    },
    clearSearch() {
      this.taxName = null;
      this.taxGroup = null;
    },
    resetModal() {
      this.editMode = false;
      this.updateMode = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
