import DatePicker from 'vue2-datepicker';
import taxgroup from '../../taxgroup';
import TaxType from '../../taxType';
import TaxSection from '../../taxSection';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility'
export default {
  name: 'TaxMasterDetails',
  components: {
    DatePicker,
    taxgroup,
    TaxType,
    TaxSection
  },
  data() {
    return {
      showTaxMasterDetails: false,
      showTaxGroupList: false,
      showTaxTypeList: false,
      showTaxSectionList: false,
      showValueSetModal: false,
      vsetCode: null,
      loader: false,
      editMode: false,
      updateMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      taxForm: {
        tax_name: null,
        tax_master_id: 0,
        tax_group: null,
        tax_group_id: null,
        tax_type: null,
        tax_type_id: null,
        section: null,
        tax_section_id: null,
        tax_rate: null,
        tax_basis: null,
        tax_basis_meaning: null,
        start_date: null,
        end_date: null,
        adhoc: false,
        recovery_flag: false,
        recovery_percentage: null,
        rounding_factor: null
      },      
      start_date_end_date: [],
    };
  },
  mounted() {
    this.eventBus.$off('createFormActions');
    this.eventBus.$off('taxMasterDetails');
    this.eventBus.$on('createFormActions', actionName => {
      if (actionName === 'edit' || actionName === 'update') {
        this.editMode = false;
        this.updateMode = true;
      }
      if (actionName === 'add') {
        this.resetform();
        this.showAlert = false;
        this.editMode = true;
        this.updateMode = true;
        this.showTaxCatDetails = true;
      }
      if (actionName === 'save' && this.updateMode) {
        this.addeditTaxMasterDetails();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', { id: this.taxForm.tax_master_id });
      }
    });
    this.eventBus.$on('taxMasterDetails', Obj => {
      this.getTaxMasterDetails(Obj.tax_master_id);
    });
  },
  methods: {
    addeditTaxMasterDetails() {
      const payload = {
        adhoc: this.taxForm.adhoc,
        end_date: commonHelper.formattedDate(this.start_date_end_date[1]),
        recovery_flag: this.taxForm.recovery_flag,
        recovery_percentage: this.taxForm.recovery_percentage,
        rounding_factor: this.taxForm.rounding_factor,
        start_date: commonHelper.formattedDate(this.start_date_end_date[0]),
        tax_basis: this.taxForm.tax_basis,
        tax_group_id: this.taxForm.tax_group_id,
        tax_master_id: this.taxForm.tax_master_id,
        tax_name: this.taxForm.tax_name,
        tax_rate: this.taxForm.tax_rate,
        tax_section_id: this.taxForm.tax_section_id,
        tax_type_id: this.taxForm.tax_type_id
      };
      this.loader = true;
      this.$store
        .dispatch('defineTaxMaster/addeditTaxMasterDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false;
            this.updateMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.taxForm = resp.data.data;
            this.start_date_end_date = [
              new Date(this.taxForm.start_date),
              new Date(this.taxForm.end_date)
            ];
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getTaxMasterDetails(tax_master_id) {
      this.$store
        .dispatch('defineTaxMaster/getTaxMasterDetails', tax_master_id)
        .then(response => {
          const results = response.data.data;
          this.taxForm = results;
          this.start_date_end_date = [
            new Date(this.taxForm.start_date),
            new Date(this.taxForm.end_date)
          ];
        });
    },
    openTaxGroupList() {
      this.showTaxGroupList = true;
    },
    selectTaxGroup(item) {
      this.taxForm.tax_group = item.tax_group;
      this.taxForm.tax_group_id = item.tax_group_id;
      this.showTaxGroupList = false;
    },
    openTaxTypeList() {
      this.showTaxTypeList = true;
    },
    selectTaxType(item) {
      this.taxForm.tax_type = item.tax_type;
      this.taxForm.tax_type_id = item.tax_type_id;
      this.showTaxTypeList = false;
    },
    openTaxSectionList() {
      this.showTaxSectionList = true;
    },
    selectTaxSection(item) {
      this.taxForm.section = item.section;
      this.taxForm.tax_section_id = item.tax_section_id;
      this.showTaxSectionList = false;
    },
    resetform() {
      this.taxForm.tax_master_id = 0;
      this.taxForm.tax_name = null;
      this.taxForm.tax_group_id = null;
      this.taxForm.tax_group = null;
      this.taxForm.tax_type_id = null;
      this.taxForm.tax_type = null;
      this.taxForm.tax_section_id = null;
      this.taxForm.section = null;
      this.taxForm.tax_rate = null;
      this.taxForm.tax_basis_meaning = null;
      this.taxForm.tax_basis = null;
      this.taxForm.start_date = null;
      this.taxForm.end_date = null;
      this.taxForm.start_date_end_date = [];
      this.taxForm.recovery_percentage = null;
      this.taxForm.rounding_factor = null;
      this.taxForm.adhoc = false;
      this.taxForm.recovery_flag = false;
      this.responseMsg = ''
      this.showAlert = false;
      this.isSuccess = false;
    },
    resetModal() {
      this.editMode = false;
      this.updateMode = false;
    },

    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.TAX_BASIS) {
        this.taxForm.tax_basis = item.value_code;
        this.taxForm.tax_basis_meaning = item.value_meaning;

      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode){
      if (vsetCode === appStrings.VALUESETTYPE.TAX_BASIS) {
        this.taxForm.tax_basis = null;
        this.taxForm.tax_basis_meaning = null;

      }
    }
  }
};
